import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Radio,
  FormControlLabel,
  FormControl,
  TextField,
  RadioGroup,
} from '@mui/material';
import {
  IndividualStepWrap,
  SubmitButton,
  PreviousNextWrap,
  RightArrow,
  sectionStyles,
} from './styles';
import { PatternFormat } from 'react-number-format';

export default function StepTwo({ request, setRequest, nextStep, prevStep }) {
  const [expandOneBCD, setExpandOneBCD] = useState(false);
  const [expandOneBC, setExpandOneBC] = useState(false);
  const [expandTwoBCD, setShowTwoBCD] = useState(false);
  const [showTwoB, setShowTwoB] = useState(false);
  const [expandFourBC, setExpandFourBC] = useState(false);
  const [expandFive, setExpandFive] = useState(false);

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setRequest({
      ...request,
      [name]: value === '' ? null : isNaN(value) ? value : parseInt(value, 10),
    });
  };

  const handleRadioChange = (field, accordionSetter) => (e) => {
    const value = e.target.value === 'yes';
    setRequest({
      ...request,
      [field]: value,
    });
    if (accordionSetter) {
      accordionSetter(value);
    }
  };

  const handlePatternFieldChange = (values, name) => {
    const { value } = values;
    setRequest({
      ...request,
      [name]: value === '' ? null : value,
    });
  };

  const canContinue =
    request.methodId != null &&
    request.q1a != null &&
    request.q2a != null &&
    // ((request.q1a == false && request.q2a == false) || request.q2Statement != null) &&
    request.q3 != null &&
    request.q4a != null &&
    request.q5a != null;

  return (
    <>
      <IndividualStepWrap>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className='section_header'>
              The taxpayer’s present overall method of accounting is *:
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormControl component='fieldset'>
              <RadioGroup
                row
                aria-label='accounting method'
                name='methodId'
                value={request.methodId ?? ''}
                onChange={handleFieldChange}
                sx={{ gap: '40px' }}
              >
                <FormControlLabel value={1} control={<Radio />} label='Cash' />
                <FormControlLabel value={2} control={<Radio />} label='Accrual' />
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* SECTION ONE */}
          <Grid container spacing={1} sx={sectionStyles}>
            <Grid item xs={1} sm={0.5} alignItems='flex-start'>
              <div className='section_header'>1.</div>
            </Grid>
            <Grid item xs={11} sm={11.5}>
              <div className='section_header'>
                a. Does the applicant (or any present or former consolidated group in which the
                applicant was a member during the applicable tax year(s)) have any federal income
                tax return(s) under examination by the IRS? *
              </div>
              <Accordion expanded={expandOneBCD} elevation={0} disableGutters>
                <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
                  <div className='sectionAnswer'>
                    <FormControl component='fieldset'>
                      <RadioGroup
                        row
                        aria-label='federal tax return'
                        name='q1a'
                        value={request.q1a ? 'yes' : request.q1a === false ? 'no' : ''}
                        onChange={handleRadioChange('q1a', setExpandOneBCD)}
                        sx={{ gap: '40px' }}
                      >
                        <FormControlLabel value='yes' control={<Radio />} label='Yes' />
                        <FormControlLabel value='no' control={<Radio />} label='No' />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <div className='section_header'>
                    b. Is the method of accounting the applicant is requesting to change an issue
                    under consideration?
                  </div>

                  <Accordion expanded={expandOneBC} elevation={0} disableGutters>
                    <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
                      <div className='sectionAnswer'>
                        <FormControl component='fieldset'>
                          <RadioGroup
                            row
                            aria-label='method of accounting'
                            name='q1b'
                            value={request.q1b ? 'yes' : request.q1b === false ? 'no' : ''}
                            onChange={handleRadioChange('q1b', setExpandOneBC)}
                            sx={{ gap: '40px' }}
                          >
                            <FormControlLabel value='yes' control={<Radio />} label='Yes' />
                            <FormControlLabel value='no' control={<Radio />} label='No' />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <div>
                        <div className='section_header'>
                          c. If yes, please provide the name and phone number of examining agent and
                          the years under examination:
                        </div>
                        <Grid container spacing={3} className='sectionAnswer'>
                          <Grid item xs={12}>
                            <TextField
                              value={request.q1cName ?? ''}
                              name={'q1cName'}
                              onChange={handleFieldChange}
                              fullWidth
                              placeholder='Name'
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <PatternFormat
                              placeholder='Phone number'
                              size='small'
                              variant='outlined'
                              value={request.q1cPhone ?? ''}
                              onValueChange={(values) =>
                                handlePatternFieldChange(values, 'q1cPhone')
                              }
                              format='(###) ### ####'
                              customInput={TextField}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              value={request.q1cTaxYears ?? ''}
                              name='q1cTaxYears'
                              onChange={handleFieldChange}
                              fullWidth
                              placeholder='Years under examination'
                            />
                          </Grid>
                        </Grid>
                        <div className='section_header'>
                          d. Has a copy of this Form 3115 been provided to the Appeals
                          officer/counsel for the government identified on line 8(c)?
                        </div>
                        <div className='sectionAnswer' style={{ marginBottom: 0 }}>
                          <FormControl component='fieldset'>
                            <RadioGroup
                              row
                              aria-label='q1d'
                              name='q1d'
                              value={request.q1d ? 'yes' : request.q1d === false ? 'no' : ''}
                              onChange={handleRadioChange('q1d')}
                              sx={{ gap: '40px' }}
                            >
                              <FormControlLabel value='yes' control={<Radio />} label='Yes' />
                              <FormControlLabel value='no' control={<Radio />} label='No' />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          {/* SECTION TWO */}
          <Grid container spacing={1} sx={sectionStyles}>
            <Grid item xs={1} sm={0.5} alignItems='flex-start'>
              <div className='section_header'>2.</div>
            </Grid>
            <Grid item xs={11} sm={11.5}>
              <div className='section_header'>
                a. Does the applicant (or any present or former consolidated group in which the
                applicant was a member during the applicable year(s)) have any Federal income tax
                return(s) before Appeals or a Federal Court? *
              </div>
              <Accordion expanded={expandTwoBCD} elevation={0} disableGutters>
                <AccordionSummary aria-controls='panel2a-content' id='panel2a-header'>
                  <div className='sectionAnswer'>
                    <FormControl component='fieldset'>
                      <RadioGroup
                        row
                        aria-label='federal tax return'
                        name='q2a'
                        value={request.q2a ? 'yes' : request.q2a === false ? 'no' : ''}
                        onChange={handleRadioChange('q2a', setShowTwoBCD)}
                        sx={{ gap: '40px' }}
                      >
                        <FormControlLabel value='yes' control={<Radio />} label='Yes' />
                        <FormControlLabel value='no' control={<Radio />} label='No' />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <div className='section_header'>
                    b. Is the method of accounting the applicant is requesting to change an issue
                    under consideration by Appeals and/or a federal court?
                  </div>
                  <Accordion expanded={showTwoB} elevation={0} disableGutters>
                    <AccordionSummary aria-controls='panel2b-content' id='panel2b-header'>
                      <div className='sectionAnswer'>
                        <FormControl component='fieldset'>
                          <RadioGroup
                            row
                            name='q2b'
                            value={request.q2b ? 'yes' : request.q2b === false ? 'no' : ''}
                            onChange={handleRadioChange('q2b', setShowTwoB)}
                            sx={{ gap: '40px' }}
                          >
                            <FormControlLabel value='yes' control={<Radio />} label='Yes' />
                            <FormControlLabel value='no' control={<Radio />} label='No' />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <div className='section_header'>
                        c. If yes, please provide the name and phone number of the appeals
                        officer/counsel for the government and the relevant tax years.
                      </div>
                      <Grid container spacing={3} className='sectionAnswer'>
                        <Grid item xs={12}>
                          <TextField
                            value={request.q2cName ?? ''}
                            name='q2cName'
                            onChange={handleFieldChange}
                            fullWidth
                            placeholder='Name'
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <PatternFormat
                            placeholder='Phone number'
                            size='small'
                            variant='outlined'
                            value={request.q2cPhone ?? ''}
                            onValueChange={(values) => handlePatternFieldChange(values, 'q2cPhone')}
                            format='(###) ### ####'
                            customInput={TextField}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            value={request.q2cTaxYears ?? ''}
                            name='q2cTaxYears'
                            onChange={handleFieldChange}
                            fullWidth
                            placeholder='Relevant tax years'
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          {/* Q1 / Q2 FILE SECTION */}
          {(request.q1a || request.q2a) && (
            <Grid container spacing={1} sx={sectionStyles}>
              <Grid item xs={1} sm={0.5} alignItems='flex-start'>
                <div className='section_header' />
              </Grid>
              <Grid item xs={11} sm={11.5}>
                <div className='section_header'>
                  If the applicant answered yes to Question 1a and/or 2a with respect to any present
                  or former consolidated group, please attach statement with name, identification
                  number, address and tax years during which the applicant was a member that is
                  under examination
                </div>
                <TextField
                  value={request.q2Statement ?? ''}
                  name='q2Statement'
                  onChange={handleFieldChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          )}

          {/* SECTION THREE */}
          <Grid container spacing={1} sx={sectionStyles}>
            <Grid item xs={1} sm={0.5} alignItems='flex-start'>
              <div className='section_header'>3.</div>
            </Grid>
            <Grid item xs={11} sm={11.5}>
              <div className='section_header'>
                If, for federal income tax purposes, the applicant is either an entity (including a
                limited liability company) treated as a partnership or an S corporation, is it
                requesting a change from a method of accounting that is an issue under consideration
                in an examination, before Appeals, or before a federal court, with respect to a
                federal income tax return of a partner, member, or shareholder of that entity? *
              </div>
              <div className='sectionAnswer'>
                <FormControl component='fieldset'>
                  <RadioGroup
                    row
                    aria-label='federal tax return'
                    value={request.q3 ? 'yes' : request.q3 === false ? 'no' : ''}
                    onChange={handleRadioChange('q3')}
                    sx={{ gap: '40px' }}
                  >
                    <FormControlLabel value='yes' control={<Radio />} label='Yes' />
                    <FormControlLabel value='no' control={<Radio />} label='No' />
                  </RadioGroup>
                </FormControl>
              </div>
            </Grid>
          </Grid>

          {/* SECTION FOUR */}
          <Grid container spacing={1} sx={sectionStyles}>
            <Grid item xs={1} sm={0.5} alignItems='flex-start'>
              <div className='section_header'>4.</div>
            </Grid>
            <Grid item xs={11} sm={11.5}>
              <div className='section_header'>
                a. Has the applicant, its predecessor, or a related party requested or made a change
                in accounting method or accounting period in the past 5 years? *
              </div>
              <Accordion expanded={expandFourBC} elevation={0} disableGutters>
                <AccordionSummary aria-controls='panel5a-content' id='panel5a-header'>
                  <div className='sectionAnswer'>
                    <FormControl component='fieldset'>
                      <RadioGroup
                        row
                        aria-label='accounting change'
                        value={request.q4a ? 'yes' : request.q4a === false ? 'no' : ''}
                        onChange={handleRadioChange('q4a', setExpandFourBC)}
                        sx={{ gap: '40px' }}
                      >
                        <FormControlLabel value='yes' control={<Radio />} label='Yes' />
                        <FormControlLabel value='no' control={<Radio />} label='No' />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <div>
                    <div className='section_header'>
                      b. If yes, please attach statement describing each change and the year of
                      change
                    </div>
                    <div className='sectionAnswer'>
                      <TextField
                        value={request.q4b ?? ''}
                        name='q4b'
                        onChange={handleFieldChange}
                        fullWidth
                      />
                    </div>
                    <div className='section_header'>
                      c. If the application was withdrawn, not perfected, or denied, or if a Consent
                      Agreement was sent to the taxpayer but was not signed and returned to the IRS,
                      or if the change was not made, include an explanation
                    </div>
                    <TextField
                      value={request.q4c ?? ''}
                      name='q4c'
                      onChange={handleFieldChange}
                      fullWidth
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          {/* SECTION FIVE */}
          <Grid container spacing={1} sx={sectionStyles}>
            <Grid item xs={1} sm={0.5} alignItems='flex-start'>
              <div className='section_header'>5.</div>
            </Grid>
            <Grid item xs={11} sm={11.5}>
              <div className='section_header'>
                a. Does the applicant, its predecessor, or a related party currently have pending
                any request for a private letter ruling, a request for change in accounting method
                or accounting period, or request for technical advice? *
              </div>
              <Accordion expanded={expandFive} elevation={0} disableGutters>
                <AccordionSummary aria-controls='panel6a-content' id='panel6a-header'>
                  <div className='sectionAnswer'>
                    <FormControl component='fieldset'>
                      <RadioGroup
                        row
                        aria-label='q5a'
                        name='q5a'
                        value={request.q5a ? 'yes' : request.q5a === false ? 'no' : ''}
                        onChange={handleRadioChange('q5a', setExpandFive)}
                        sx={{ gap: '40px' }}
                      >
                        <FormControlLabel value='yes' control={<Radio />} label='Yes' />
                        <FormControlLabel value='no' control={<Radio />} label='No' />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <div className='section_header'>
                    b. If yes, please attach statement with the name(s) of the taxpayer,
                    identification number, the type of request and the specific issue in the
                    request:
                  </div>
                  <div className='sectionAnswer'>
                    <TextField
                      value={request.q5b ?? ''}
                      name='q5b'
                      onChange={handleFieldChange}
                      fullWidth
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>

        <PreviousNextWrap className='previousNextWrapTwo'>
          <div className='prev-direction prev-directionTwo' onClick={prevStep}>
            <RightArrow />
            <span>Back</span>
          </div>
          <SubmitButton text='Continue' disabled={!canContinue} onClick={nextStep} />
        </PreviousNextWrap>
      </IndividualStepWrap>
    </>
  );
}
