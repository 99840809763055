export const applicantTypes = [
  { value: 1, label: 'Individual' },
  { value: 2, label: 'Corporation' },
  { value: 3, label: 'Controlled foreign' },
  { value: 4, label: '10/50 corporation' },
  { value: 5, label: 'Qualified personal service corporation' },
  { value: 6, label: 'Exempt organization' },
  { value: 7, label: 'Cooperative' },
  { value: 8, label: 'Partnership' },
  { value: 9, label: 'S corporation' },
  { value: 10, label: 'Insurance co. (Sec. 816 (a))' },
  { value: 11, label: 'Insurance co.' },
  { value: 12, label: 'Other' },
];

export const applicantTypes2848 = [
  { value: 'A', label: 'Attorney' },
  { value: 'B', label: 'CPA' },
  { value: 'C', label: 'Enrolled Agent' },
  { value: 'D', label: 'Officer' },
  { value: 'E', label: 'Full Time Employee' },
  { value: 'F', label: 'Family Member' },
  { value: 'G', label: 'Enrolled Actuary' },
  { value: 'H', label: 'Unenrolled Return Preparer' },
  { value: 'K', label: 'Qualifying Student' },
  { value: 'R', label: 'Retirement Plan Agent' },
];
