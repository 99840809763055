import React, { useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  RadioGroup,
  Radio,
  TextField,
  Typography,
  Stack,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { PatternFormat } from 'react-number-format';
import { IndividualStepWrap, SubmitButton, PreviousNextWrap, RightArrow } from './styles';
import { applicantTypes2848 } from './formData';
import { allStates } from './mockData';

export default function StepThree({ request, setRequest, nextStep, prevStep }) {
  const [show2848, setShow2848] = useState(false);

  const canSubmit =
    request.q6a != null &&
    request.q6b != null &&
    request.q7a != null &&
    (request.q7a == true || request.q7b != null);

  const f2848 = request.f2848;

  const handleNumericFieldChange = (event) => {
    const { name, value } = event.target;
    setRequest({
      ...request,
      f2848: {
        ...f2848,
        [name]: value === '' ? null : isNaN(value) ? value : parseInt(value, 10),
      },
    });
  };

  const handlePtinChange = (event) => {
    const { value } = event.target;
    if (/^P?\d{0,8}$/i.test(value)) {
      setRequest({ ...request, f2848: { ...f2848, ptin: value === '' ? null : value } });
    }
  };

  return (
    <IndividualStepWrap>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h6' className='section_header'>
            6. a. Please describe the applicant’s trade(s) or business(es) and the principal
            business activity code for each *
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={request.q6a ?? ''}
            onChange={(e) => setRequest({ ...request, q6a: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6' className='section_header'>
            b. Does the applicant have more than one trade or business as defined in regulations
            section 1.446-1(d)? *
          </Typography>
          <Accordion expanded={request.q6b === true} elevation={0} disableGutters>
            <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
              <FormControl component='fieldset'>
                <RadioGroup
                  row
                  aria-label='regulations section'
                  name='regulationsSection'
                  value={request.q6b ? 'yes' : request.q6b === false ? 'no' : ''}
                  onChange={(e) =>
                    setRequest({
                      ...request,
                      q6b: e.target.value === 'yes',
                    })
                  }
                  sx={{ gap: '40px' }}
                >
                  <FormControlLabel value='yes' control={<Radio />} label='Yes' />
                  <FormControlLabel value='no' control={<Radio />} label='No' />
                </RadioGroup>
              </FormControl>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <Typography variant='h6' className='section_header'>
                i. Is each trade or business accounted for separately? *
              </Typography>
              <TextField
                fullWidth
                value={request.q6i ?? ''}
                onChange={(e) => setRequest({ ...request, q6i: e.target.value })}
              />
              <Typography variant='h6' className='section_header'>
                ii. For each trade or business attach a description of the type of business: *
              </Typography>
              <TextField
                fullWidth
                value={request.q6ii ?? ''}
                onChange={(e) => setRequest({ ...request, q6ii: e.target.value })}
              />
              <Typography variant='h6' className='section_header'>
                iii. The overall method of accounting: *
              </Typography>
              <TextField
                fullWidth
                value={request.q6iii ?? ''}
                onChange={(e) => setRequest({ ...request, q6iii: e.target.value })}
              />
              <Typography variant='h6' className='section_header'>
                iv. The business has changed any accounting method in the past 5 years, and whether
                the business is changing any accounting method as part of this application or as a
                separate application: *
              </Typography>
              <TextField
                fullWidth
                value={request.q6iv ?? ''}
                onChange={(e) => setRequest({ ...request, q6iv: e.target.value })}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6' className='section_header'>
            7. Will the proposed change in accounting method be used for the taxpayer’s books and
            records and financial statements? *
          </Typography>
          <Accordion expanded={request.q7a === false} elevation={0} disableGutters>
            <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
              <FormControl component='fieldset'>
                <RadioGroup
                  row
                  aria-label='books and records'
                  name='booksAndRecords'
                  value={request.q7a ? 'yes' : request.q7a === false ? 'no' : ''}
                  onChange={(e) =>
                    setRequest({
                      ...request,
                      q7a: e.target.value === 'yes',
                    })
                  }
                  sx={{ gap: '40px' }}
                >
                  <FormControlLabel value='yes' control={<Radio />} label='Yes' />
                  <FormControlLabel value='no' control={<Radio />} label='No' />
                </RadioGroup>
              </FormControl>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0, paddingLeft: '48px' }}>
              <div className='section_header'>If no, please explain why *</div>
              <TextField
                fullWidth
                value={request.q7b ?? ''}
                onChange={(e) => setRequest({ ...request, q7b: e.target.value })}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <div className='section_header'>Additional Comments</div>
          <TextField
            fullWidth
            multiline
            rows={8}
            value={request.additionalComments ?? ''}
            onChange={(e) => setRequest({ ...request, additionalComments: e.target.value })}
            sx={{ height: 'auto !important' }}
          />
        </Grid>

        <Accordion expanded={show2848}>
          <AccordionSummary>
            <Stack
              spacing={1}
              direction='row'
              justifyContent='space-between'
              alignItems='flex-start'
            >
              <Checkbox
                checked={show2848}
                onChange={() => {
                  if (show2848) setRequest({ ...request, f2848: null });
                  setShow2848(!show2848);
                }}
              />
              <div className='section_header' style={{ marginTop: 0 }}>
                Please select if you would like Form 2848 completed (Power of Attorney)
              </div>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              className='form2848Wrap'
              spacing={{
                xs: 2,
                md: 4,
              }}
            >
              <Grid item xs={12}>
                <Typography variant='h6' className='section_header'>
                  Form 2848 Information
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Name'
                  value={f2848?.name ?? ''}
                  onChange={(e) =>
                    setRequest({ ...request, f2848: { ...f2848, name: e.target.value } })
                  }
                  size='small'
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Address'
                  value={f2848?.address ?? ''}
                  onChange={(e) =>
                    setRequest({ ...request, f2848: { ...f2848, address: e.target.value } })
                  }
                  size='small'
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label='City'
                  value={f2848?.city ?? ''}
                  onChange={(e) =>
                    setRequest({ ...request, f2848: { ...f2848, city: e.target.value } })
                  }
                  size='small'
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth size='small' variant='outlined'>
                  <InputLabel style={{ color: '#a6a6a6' }}>State</InputLabel>
                  <Select
                    value={f2848?.state ?? ''}
                    name='state'
                    onChange={(e) =>
                      setRequest({ ...request, f2848: { ...f2848, state: e.target.value } })
                    }
                    fullWidth
                    label='State'
                    placeholder='State'
                  >
                    {allStates.map((state) => (
                      <MenuItem key={state.value} value={state.value}>
                        {state.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label='Zip'
                  value={f2848?.zip ?? ''}
                  onChange={(e) =>
                    setRequest({ ...request, f2848: { ...f2848, zip: e.target.value } })
                  }
                  size='small'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label='CAF Number'
                  value={f2848?.cafNumber ?? ''}
                  onChange={(e) =>
                    setRequest({ ...request, f2848: { ...f2848, cafNumber: e.target.value } })
                  }
                  size='small'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label='PTIN'
                  value={f2848?.ptin ?? ''}
                  onChange={handlePtinChange}
                  size='small'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <PatternFormat
                  label='Phone Number'
                  placeholder='Phone Number'
                  size='small'
                  variant='outlined'
                  value={f2848?.phoneNumber ?? ''}
                  onValueChange={(values) =>
                    setRequest({
                      ...request,
                      f2848: { ...f2848, phoneNumber: values.formattedValue },
                    })
                  }
                  format='(###) ###-####'
                  customInput={TextField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <PatternFormat
                  placeholder='Fax Number'
                  label='Fax Number'
                  size='small'
                  variant='outlined'
                  value={f2848?.faxNumber ?? ''}
                  onValueChange={(values) =>
                    setRequest({
                      ...request,
                      f2848: { ...f2848, faxNumber: values.formattedValue },
                    })
                  }
                  format='(###) ###-####'
                  customInput={TextField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size='small' variant='outlined'>
                  <InputLabel style={{ color: '#a6a6a6' }}>State of Certification</InputLabel>
                  <Select
                    value={f2848?.stateOfCertification ?? ''}
                    name='State of Certification'
                    onChange={(e) =>
                      setRequest({
                        ...request,
                        f2848: { ...f2848, stateOfCertification: e.target.value },
                      })
                    }
                    fullWidth
                    label='State of Certification'
                    placeholder='State of Certification'
                  >
                    {allStates.map((state) => (
                      <MenuItem key={state.value} value={state.value}>
                        {state.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label='License Number'
                  value={f2848?.licenseNumber ?? ''}
                  onChange={(e) =>
                    setRequest({ ...request, f2848: { ...f2848, licenseNumber: e.target.value } })
                  }
                  size='small'
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant='h5'
                  gutterBottom
                  className='section_header'
                  sx={{ marginBottom: '20px !important' }}
                >
                  Type of Applicant (please select one): *
                </Typography>
                <FormControl component='fieldset'>
                  <RadioGroup
                    name='applicantType'
                    value={f2848?.applicantType ?? ''}
                    onChange={handleNumericFieldChange}
                    row
                    sx={{ flexWrap: 'wrap' }}
                  >
                    {applicantTypes2848.map((type) => (
                      <FormControlLabel
                        key={type.value}
                        value={type.value}
                        control={
                          <Radio
                            sx={{
                              '&.Mui-checked': {
                                color: 'primary.main',
                              },
                            }}
                          />
                        }
                        label={type.label}
                        sx={{
                          width: '100%',
                          '@media (min-width: 600px)': {
                            width: '45%',
                          },
                        }}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <PreviousNextWrap style={{ flexWrap: 'wrap' }}>
        <div className='lastStepButtons'>
          <div className='prev-direction prev-directionThree' onClick={prevStep}>
            <RightArrow />
            <span>Back</span>
          </div>
        </div>
        <SubmitButton noArrow fullWidth text='Submit' onClick={nextStep} disabled={!canSubmit} />
      </PreviousNextWrap>
    </IndividualStepWrap>
  );
}
