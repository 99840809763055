import React, { useState } from 'react';
import {
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  FormLabel,
  Tooltip,
} from '@mui/material';
import { PatternFormat } from 'react-number-format';
import {
  SubmitButton,
  IndividualStepWrap,
  PreviousNextWrap,
  PlusCircle,
  PropertyBox,
} from './styles';
import { allStates } from './mockData';
import { applicantTypes } from './formData';

import InfoCircle from './infoCircle.png';

export default function StepOne({ request, setRequest, nextStep }) {
  const [hasJointFiler, setHasJointFiler] = useState(false);

  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;

  const {
    applicantTypeId,
    otherApplicantType,
    jointFiler,
    jointFilerSsn,
    applicantName,
    taxpayerId,
    properties,
    applicantAddress,
    city,
    state,
    zip,
    contactName,
    contactPhone,
    emailAddresses,
    taxYear,
    principalBusinessActivityCode,
  } = request;

  const canContinue =
    applicantTypeId != 0 &&
    (applicantTypeId != 12 || otherApplicantType != null) &&
    (!hasJointFiler || applicantTypeId != 1 || (jointFiler != null && jointFilerSsn?.replace(/\D/g, '')?.length == 9)) &&
    applicantName != null &&
    taxpayerId?.replace(/\D/g, '')?.length == 9 &&
    properties.some((p) => p.propertyAddress != null) &&
    applicantAddress != null &&
    city != null &&
    state != null &&
    zip?.replace(/\D/g, '')?.length >= 5 &&
    contactName != null &&
    contactPhone?.replace(/\D/g, '')?.length == 10 &&
    emailAddresses.some((e) => e.email != null && e.isValid);

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setRequest({
      ...request,
      [name]: value === '' ? null : value,
    });
  };

  const handleNumericFieldChange = (event) => {
    const { name, value } = event.target;
    setRequest({
      ...request,
      [name]: value === '' ? null : isNaN(value) ? value : parseInt(value, 10),
    });
  }

  const handleBonusChange = (index) => {
    setRequest((prevState) => {
      const updatedProperties = prevState.properties.map((property, i) =>
        i === index
          ? { ...property, usingBonus: !property.usingBonus }
          : property
      );
      return { ...prevState, properties: updatedProperties };
    });
  };

  const handlePropertyChange = (index) => (event) => {
    const { name, value } = event.target;
    setRequest((prevState) => {
      const updatedProperties = prevState.properties.map((property, i) =>
        i === index ? { ...property, [name]: value === '' ? null : value } : property
      );
      return { ...prevState, properties: updatedProperties };
    });
  };

  const handleDisregardedEntityChange = (index) => (values) => {
    const { formattedValue } = values;
    setRequest((prevState) => {
      const updatedProperties = prevState.properties.map((property, i) =>
        i === index
          ? { ...property, disregardedEntityTaxID: formattedValue === '' ? null : formattedValue }
          : property
      );
      return { ...prevState, properties: updatedProperties };
    });
  };

  const handlePatternFieldChange = (values, name) => {
    const { formattedValue } = values;
    setRequest({
      ...request,
      [name]: formattedValue === '' ? null : formattedValue,
    });
  };

  const handleNumericPatternFieldChange = (values, name) => {
    const { floatValue } = values;
    setRequest({
      ...request,
      [name]: floatValue,
    });
  };

  const validateEmail = (index) => (event) => {
    const isValid = event.target.value
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
    const newEmails = [...emailAddresses];
    newEmails[index].isValid = Boolean(isValid);
    setRequest({ ...request, emailAddresses: newEmails });
  };

  const handleEmailChange = (index) => (event) => {
    const newEmails = [...emailAddresses];
    newEmails[index].email = event.target.value;
    setRequest({ ...request, emailAddresses: newEmails });
  };

  const addEmailField = () => {
    if (emailAddresses.length < 5) {
      const newEmails = [...emailAddresses, { email: '', isValid: true }];
      setRequest({ ...request, emailAddresses: newEmails });
    }
  };

  const addPropertySection = () => {
    const newProperties = [
      ...properties,
      {
        disregardedEntityName: null,
        disregardedEntityTaxID: null,
        propertyAddress: null,
        usingBonus: false,
      },
    ];
    setRequest({ ...request, properties: newProperties });
  };

  return (
    <IndividualStepWrap>
      <Typography
        variant='h6'
        gutterBottom
        className='section_header'
        sx={{ marginBottom: '20px !important' }}
      >
        Type of Applicant (please select one): *
      </Typography>
      <Grid container spacing={2.5}>
        <Grid item xs={12}>
          <FormControl component='fieldset'>
            <RadioGroup
              name='applicantTypeId'
              value={applicantTypeId}
              onChange={handleNumericFieldChange}
              row
              sx={{ flexWrap: 'wrap' }}
            >
              {applicantTypes.map((type) => (
                <FormControlLabel
                  key={type.value}
                  value={type.value}
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: 'primary.main',
                        },
                      }}
                    />
                  }
                  label={type.label}
                  sx={{
                    width: '100%',
                    '@media (min-width: 600px)': {
                      width: '45%',
                    },
                  }}
                />
              ))}
            </RadioGroup>
            {applicantTypeId == 12 && (
              <TextField
                sx={{ marginTop: '20px' }}
                placeholder='Please type another option here'
                variant='outlined'
                size='small'
                fullWidth
                value={otherApplicantType ?? ''}
                name='otherApplicantType'
                onChange={handleFieldChange}
              />
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6' className='section_header'>
            Name of Filer: *
          </Typography>
        </Grid>
        {applicantTypeId != 1 ? (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                placeholder='Applicant Name'
                size='small'
                variant='outlined'
                value={applicantName ?? ''}
                name='applicantName'
                onChange={handleFieldChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PatternFormat
                placeholder='EIN'
                size='small'
                variant='outlined'
                value={taxpayerId ?? ''}
                onValueChange={(values) => handlePatternFieldChange(values, 'taxpayerId')}
                format='##-#######'
                customInput={TextField}
                fullWidth
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={6}>
              <TextField
                placeholder='Name'
                size='small'
                variant='outlined'
                value={applicantName ?? ''}
                name='applicantName'
                onChange={handleFieldChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PatternFormat
                placeholder='SSN'
                size='small'
                variant='outlined'
                value={taxpayerId ?? ''}
                onValueChange={(values) => handlePatternFieldChange(values, 'taxpayerId')}
                format='###-##-####'
                customInput={TextField}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Is there a Joint Filer?</FormLabel>
              <RadioGroup
                row
                value={hasJointFiler}
                name='jointFiler'
                onChange={(event) => setHasJointFiler(event.target.value === 'true')}
                sx={{ marginTop: '8px' }}
              >
                <FormControlLabel value={true} control={<Radio />} label='Yes' />
                <FormControlLabel value={false} control={<Radio />} label='No' />
              </RadioGroup>
            </Grid>
            {hasJointFiler && (
              <>
                <Grid item xs={12} md={6}>
                  <TextField
                    placeholder='Joint Filer Name'
                    size='small'
                    variant='outlined'
                    value={jointFiler ?? ''}
                    name='jointFiler'
                    onChange={handleFieldChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <PatternFormat
                    placeholder='Joint Filer SSN'
                    size='small'
                    variant='outlined'
                    value={jointFilerSsn ?? ''}
                    onValueChange={(values) => handlePatternFieldChange(values, 'jointFilerSsn')}
                    format='###-##-####'
                    customInput={TextField}
                    fullWidth
                  />
                </Grid>
              </>
            )}
          </>
        )}

        <Grid item xs={12}>
          <Typography variant='h6' className='section_header'>
            Property Address: *
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <PropertyBox>
            {properties.map((property, index) => (
              <Grid container spacing={3} key={index} className='propAddressWrap'>
                <Grid item xs={12} sm={6}>
                  <TextField
                    placeholder='Name of LLC'
                    size='small'
                    variant='outlined'
                    value={property.disregardedEntityName ?? ''}
                    name='disregardedEntityName'
                    onChange={handlePropertyChange(index)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <PatternFormat
                    placeholder='EIN'
                    size='small'
                    variant='outlined'
                    value={property.disregardedEntityTaxID ?? ''}
                    onValueChange={handleDisregardedEntityChange(index)}
                    format='##-#######'
                    customInput={TextField}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    placeholder='Property Address *'
                    size='small'
                    variant='outlined'
                    value={property.propertyAddress ?? ''}
                    name='propertyAddress'
                    onChange={handlePropertyChange(index)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Radio
                    value={property.usingBonus}
                    checked={property.usingBonus}
                    onClick={() => handleBonusChange(index)}
                  />
                  <FormLabel>
                    Applicant will be utilizing bonus for this entity (if applicable)
                  </FormLabel>
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
              <PlusCircle onClick={addPropertySection} />
            </Grid>
          </PropertyBox>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' className='section_header'>
            Address of Applicant: *
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder='Street Address'
            size='small'
            variant='outlined'
            value={applicantAddress ?? ''}
            name='applicantAddress'
            onChange={handleFieldChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            placeholder='City'
            size='small'
            variant='outlined'
            value={city ?? ''}
            name='city'
            onChange={handleFieldChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4} className='statfieldwrapShowLabel'>
          <FormControl fullWidth size='small' variant='outlined'>
            <InputLabel style={{ color: '#a6a6a6' }}>State</InputLabel>
            <Select
              value={state ?? ''}
              name='state'
              onChange={handleFieldChange}
              fullWidth
              label='State'
            >
              {allStates.map((state) => (
                <MenuItem key={state.value} value={state.value}>
                  {state.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <PatternFormat
            placeholder='Zip Code'
            size='small'
            variant='outlined'
            value={zip ?? ''}
            onValueChange={(values) => handlePatternFieldChange(values, 'zip')}
            format='#####'
            customInput={TextField}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' className='section_header'>
            Contact Person: *
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            placeholder='Name'
            size='small'
            variant='outlined'
            value={contactName ?? ''}
            name='contactName'
            onChange={handleFieldChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PatternFormat
            placeholder='Phone'
            size='small'
            variant='outlined'
            value={contactPhone ?? ''}
            onValueChange={(values) => handlePatternFieldChange(values, 'contactPhone')}
            format='(###) ###-####'
            customInput={TextField}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' className='section_header'>
            Send Completed Forms To: *
          </Typography>
        </Grid>
        <Grid item xs={10} sm={11}>
          {emailAddresses.map((email, index) => (
            <Grid
              item
              xs={12}
              key={index}
              sx={{
                marginTop: 2,
                ':first-of-type': { marginTop: 0 },
              }}
            >
              <TextField
                error={!email.isValid}
                placeholder={`Email ${index + 1}`}
                size='small'
                variant='outlined'
                value={email.email ?? ''}
                name={`email${index}`}
                onChange={handleEmailChange(index)}
                onBlur={validateEmail(index)}
                fullWidth
              />
            </Grid>
          ))}
        </Grid>
        {emailAddresses.length < 5 && (
          <Grid
            item
            xs={2}
            sm={1}
            sx={{ display: 'flex', alignItems: 'flex-start', marginTop: '6px' }}
          >
            <PlusCircle onClick={addEmailField} />
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography variant='h6' className='section_header'>
            Tax Year of Change:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth size='small' variant='outlined'>
            <InputLabel style={{ color: '#a6a6a6' }}>Tax Year</InputLabel>
            <Select
              value={taxYear ?? ''}
              name='taxYear'
              onChange={handleNumericFieldChange}
              placeholder='Tax Year of Change'
            >
              {[currentYear, previousYear].map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' className='section_header df aic gap1'>
            Principal Business Activity Code:
            <Tooltip
              classes={{ tooltip: 'customTooltip' }}
              title='This is a 6-digit code that can be found on the top left of your 1065 tax form.'
            >
              <img style={{ maxWidth: 19, height: 'auto' }} src={InfoCircle} alt='InfoCircle' />
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <PatternFormat
            placeholder='Code'
            size='small'
            variant='outlined'
            value={principalBusinessActivityCode ?? ''}
            onValueChange={(values) =>
              handleNumericPatternFieldChange(values, 'principalBusinessActivityCode')
            }
            format='######'
            customInput={TextField}
            fullWidth
          />
        </Grid>
      </Grid>

      <PreviousNextWrap>
        <SubmitButton text='Continue' onClick={nextStep} disabled={!canContinue} fullWidth />
      </PreviousNextWrap>
    </IndividualStepWrap>
  );
}
