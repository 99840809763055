import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-modal';

import { MilkyWayWrap, Planet } from './orbitsStyles';
import CategoryCard from './CategoryCard';
import { categories } from './data';
import { MilkyWay, AstroidBelt } from './illustrations';

export default function Orbits() {
  const [activeCategory, setActiveCategory] = useState(1);

  const handleClick = (categoryId) => {
    setActiveCategory(categoryId === activeCategory ? null : categoryId);
  };

  return (
    <MilkyWayWrap>
      <MilkyWay className='milkywayImg' />
      <div className='innerPlanetaryRings'>
        <AstroidBelt className='astroidBelt' />
        {categories.map((category) => (
          <PlanetSurface
            key={category.id}
            category={category}
            activeCategory={activeCategory}
            handleClick={handleClick}
          />
        ))}
      </div>
    </MilkyWayWrap>
  );
}

const customStyles = {
  content: {
    width: '100vw',
    height: '100vh',
    left: '0',
    top: '0',
    padding: '0px',
    border: 'none',
    margin: '0px',
    borderRadius: '0px',
    backgroundColor: 'transparent',
  },
};

function PlanetSurface({ category, activeCategory, handleClick }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const isActive = activeCategory === category.id;

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        setIsExpanded(false);
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <div className='planet-or'>
      <Planet
        current={isActive}
        key={category.id}
        isExpanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className='planet-title'>{category.title}</div>
        <div className='planet-quantity'>{category.items.length}</div>
      </Planet>
      <Modal
        isOpen={isExpanded}
        onRequestClose={() => setIsExpanded(false)}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <CategoryCard i={category} close={() => setIsExpanded(false)} />
      </Modal>
    </div>
  );
}
