import React from 'react';

import {
  Root,
  SectionOneWrap,
  ClusterWrap,
  BioCardsWrap,
  OurValuesWrap,
  DiscoverText,
} from '../components/team/indexStyles';
import Orbits from '../components/team/Orbits';
import {
  SmallSolarSystem,
  SolidMotif,
  LargeMotif,
  LargeMotifMobile,
} from '../components/team/illustrations';
import BioCard from '../components/team/BioCard';
import LastSection from '../components/home/LastSection';
import CallButton from '../components/ui/CallButton';
import { leaderShips } from '../components/team/data';
// import CategoryCard from '../components/team/CategoryCard';
import { categories } from '../components/team/data';

export default function Team() {
  return (
    <Root>
      <SectionOneWrap>
        <ClusterWrap>
          <SolidMotif className='solidMotif' />
          <SmallSolarSystem className='smallSolarSystem' />
          <div className='headerText'>The Power Of Our Team</div>
        </ClusterWrap>
        <div className='createsThe'>
          Creates The
          <br />
          Strength In Your Study
        </div>
        <div className='ourDedicated'>OUR DEDICATED TEAM OF 50+ PROFESSIONALS -</div>
        <div className='includingSixteen'>
          includes our Accounting Team, Account Executives, Engineering Professionals and Support
          Team. That means we can handle large volumes of work and complex projects even during peak
          seasons. And thanks to 6 branches spread across multiple time zones, we have a 20-hour
          workday, which means an astonishingly swift turnaround.
        </div>
        <LargeMotif className='largeMotif' />
        <LargeMotifMobile className='largeMotifMobile' />
        <LargeMotif className='intsyMotif' />
      </SectionOneWrap>
      <div style={{ padding: '0 24px', position: 'relative' }}>
        <DiscoverText>Discover the myriad of people powering your cost seg study </DiscoverText>
        <Orbits />
        <div className='madisonFaces'>The faces of Madison SPECS</div>
        <div className='decadesTag'>
          Decades of experience and worlds of knowledge are just a tap away
        </div>
        <BioCardsWrap>
          <div className='topTwo'>
            <BioCard
              img='https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/thumbnail_eli_qnbwgi.jpg'
              nameFirst='Eli'
              nameLast='Loebenberg'
              title='CEO'
              linkedIn='https://www.linkedin.com/in/eli-loebenberg/'
              showTitle
            />
            <BioCard
              img='https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/about/moses-hildy_uujkxu.png'
              nameFirst='Moshe'
              nameLast='Hildeshaim'
              title='Director'
              linkedIn='https://www.linkedin.com/in/moshe-hildeshaim-6b2b2516/'
              showTitle
            />
          </div>
          {leaderShips.map((leader, index) => (
            <BioCard
              key={index}
              img={leader.img}
              nameFirst={leader.nameFirst}
              nameLast={leader.nameLast}
              title={leader.title}
              linkedIn={leader.linkedIn}
              twitter={leader.twitter}
              podcast={leader.podcast}
              facebook={leader.facebook}
              bigPockets={leader.bigPockets}
              youtube={leader.youtube}
              className={leader.className}
            />
          ))}
        </BioCardsWrap>
      </div>
      {/* <div className='ourValuesWrap'>
        <OurValuesWrap>
          <img
            src='https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/about-us/coin-arrow_ycrbqe.png'
            alt='coins and arrow images'
          />
          <div>
            <div className='ourValuesHeader'>OUR VLAUES</div>
            <div className='ourValuesText'>
              Since 2006, we've worked on constantly improving your study results by increasing our expertise. We are
              proud of our close relationships with our many satisfied clients.
            </div>

            <CallButton text='Our Story' to='/' />
          </div>
        </OurValuesWrap>
      </div> */}
      <LastSection
        overRides={{
          marginTop: 100,
          '@media (min-width: 1440px)': {
            marginTop: 180,
          },
        }}
        // overRides={{
        //   '@media (min-width: 1440px)': {
        //     alignItems: 'flex-end',
        //     height: 600,
        //     justifyContent: 'center',
        //   },
        // }}
        // motifOverRides={{
        //   '@media (min-width: 1440px)': {
        //     bottom: 0,
        //     height: 'auto',
        //     maxWidth: 718,
        //     top: 'unset',
        //     width: '100%',
        //   },
        // }}
      />
    </Root>
  );
}
