import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

import { Root, FormWrap } from './styles';
import StepTwo from './StepTwo';
import Banner from './Banner';
import StepOne from './StepOne';
import StepThree from './StepThree';
import FormCompleted from './FormCompleted';
import Stepper from './Steppers';
import Footer from './Footer';

const initialRequestState = {
  applicantTypeId: 0,
  otherApplicantType: null,
  applicantName: null,
  taxpayerId: null,
  jointFiler: null,
  jointFilerSsn: null,
  properties: [
    {
      disregardedEntityName: null,
      disregardedEntityTaxID: null,
      propertyAddress: null,
      usingBonus: false,
    },
  ],
  applicantAddress: null,
  city: null,
  state: null,
  zip: null,
  contactName: null,
  contactPhone: null,
  emailAddresses: [{ email: null, isValid: true }],
  taxYear: new Date().getFullYear(),
  principalBusinessActivityCode: null,
  methodId: null,
  q1a: null,
  q1b: null,
  q1cName: null,
  q1cPhone: null,
  q1cTaxYears: null,
  q1d: null,
  q2a: null,
  q2b: null,
  q2cName: null,
  q2cPhone: null,
  q2cTaxYears: null,
  q2Statement: null,
  q3: null,
  q4a: null,
  q4b: null,
  q4c: null,
  q5a: null,
  q5b: null,
  q6a: null,
  q6b: null,
  q6i: null,
  q6ii: null,
  q6iii: null,
  q6iv: null,
  q7a: null,
  q7b: null,
  additionalComments: null,
  f2848: null
};

export default function Form3115() {
  const [request, setRequest] = useState(initialRequestState);

  const [step, setStep] = useState(1);
  const formRef = useRef(null);

  const handleNextStep = () => {
    formRef.current.scrollIntoView();
    setStep(step + 1);
  };

  useEffect(() => {
    if (step === 4) {
      axios.post(
        'https://nextgen-function-app.azurewebsites.net/api/Submit3115',
        {
          ...request,
          emailAddresses: request.emailAddresses.map((e) => ({
            emailAddress: e.email,
          })),
        }
      );
    }
  }, [step]);

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  return (
    <>
      <Root>
        <Banner />
        <FormWrap ref={formRef}>
          {step !== 4 && <Stepper step={step} />}
          {step === 1 && (
            <StepOne
              request={request}
              setRequest={setRequest}
              nextStep={handleNextStep}
              prevStep={handlePrevStep}
            />
          )}
          {step === 2 && (
            <StepTwo
              request={request}
              setRequest={setRequest}
              nextStep={handleNextStep}
              prevStep={handlePrevStep}
            />
          )}
          {step === 3 && (
            <StepThree
              request={request}
              setRequest={setRequest}
              nextStep={handleNextStep}
              prevStep={handlePrevStep}
            />
          )}
          {step === 4 && <FormCompleted />}
        </FormWrap>
      </Root>
      <Footer />
    </>
  );
}
